import GemutLogo from './Gemut-logo.png';
import GemutHello from './Gemut-hello.png';
import ArrowLeft from './arrow-left.png';
import ArrowRight from './arrow-right.png';
import './App.css';

function App() {
  return (
    <div className='app'>
      <header className='header'>
        <div className='baseline'>
          <img src={GemutLogo}/>
          <label>Marion Bes - Sophrologue</label>
          <span className='spacer'>&nbsp;-&nbsp;</span>
          <label>
            <a href="tel:0630052531">06 30 05 25 31</a>
            <span className='spacer'>&nbsp;-&nbsp;</span>
            <a href="mailto:gemut.info@gmail.fr">gemut.info@gmail.fr</a>
          </label>
        </div>
        <div className='title'>
          <img src={GemutHello}/>
        </div>
        <div className="subtitle">
          <b>Et si nous organisions des séances de bien-être pour vos équipes ?</b>
          <p>L'idée est de leur offrir des moments de relaxation,</p>
          <p>de détente et de lâcher-prise</p>
        </div>
      </header>
      <div className='arrows'>
        <img src={ArrowLeft}/>
        <img src={ArrowRight}/>
      </div>
      <div className="relative">
        <div className="absolute a-one">
          <img className='al' src={ArrowLeft} />
          à la pause déjeuner, <br/>
          en fin de journée...
        </div>
        <div className="absolute a-two">
          <img className='ar' src={ArrowRight}/>
          ou encore, le matin à la fraiche !
        </div>
      </div>
      <div className='links'>
        <ul>
          <li>
            <a target="_blank" href="la-sophro-quezako.pdf">
              La sophrologie...Quezako ?
            </a>
          </li>
          <li>
          <a target="_blank" href="le-parcours-pour-se-mettre-en-pause.pdf">
              Le parcours Bien-être pour vos collaborateurs
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.linkedin.com/in/marion-bes-gem%C3%BCt/">
              Contact LinkedIn
            </a>
          </li>
          <li>
            <a target="_blank" href="a-propos.pdf">
              A propos
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
